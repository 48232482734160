import { WeatherSunny16Regular, WeatherMoon16Regular } from "@fluentui/react-icons";
import styles from "./ThemeToggleButton.module.css";

interface Props {
    theme: string;
    toggleTheme: () => void;
}

export const ThemeToggleButton = ({ theme, toggleTheme }: Props) => {
    const isDarkTheme = theme === 'dark';
    const buttonClass = `${styles.themeToggleButton} ${isDarkTheme ? styles.dark : ''}`;
    const sliderClass = `${styles.slider} ${isDarkTheme ? styles.darkSlider : ''}`;

    const handleToggle = () => {
        const newTheme = isDarkTheme ? 'light' : 'dark';
        
        // Push to dataLayer
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ component: null });
        window.dataLayer.push({
            event: 'component_interaction',
            component: {
                component_name: 'dark mode toggle',
                component_title: 'dark mode toggle',
                component_type: 'button',
                interaction_type: 'click',
                interaction_value: `toggle:${newTheme}`
            }
        });

        // Call the original toggleTheme function
        toggleTheme();
    };

    return (
        <button onClick={handleToggle} className={buttonClass}>
            <WeatherSunny16Regular className={styles.sunIcon} />
            <WeatherMoon16Regular className={styles.moonIcon} />
            <div className={sliderClass}></div>
        </button>
    );
};