export const lightTheme = {
    backgroundColor: "#fff",
    cardDescriptionTextColor: "#000",
    headerBackground: "#002d4e",
    cardStrip1Color: "#6fff6f",
    cardColor: "#fff",
    disabledCardColor: "#fff",
    headerTextColor: "#f2f2f2",
    cardTitleColor: "#002d4e",
    landingPageTitleColor: "#002d4e",
    cardFlipTextColor: "#555",
    backgroundImage: "url(/landingPageBackground.jpg)",
    iconFillColor: "#01426A",
    cardDescriptionHoverTextColor: "#4b4b4b",
    cardTitleHoverColor: "#01548f",
    cardFlipHoverTextColor: "#01548f",
    cardLinkTextColor: "#007bff",
    chatBackgroundImage: "url(/background.jpg)",
    chatBackgroundColor: "",//"#8dc8e8",
    chatTitleColor: "#002d4e",
    chatIconFillColor: "#01426A",
    chatCommandTextColor: "#002d4e",
    chatCommandContainerBackgroundColor: "",
    chatSidebarBackground: "#F9F9F9",
    chatSidebarItem: "#333",
    chatSidebarActiveItem: "#f0f0f0",
    chatSidebarItemHover: "#EEE",
    chatSidebarItemBorder: "#ccc",
    chatSidebarButtonBackground: "f9f9f9",
    chatSidebarButtonTextColor: "#333",
    chatSidebarButtonHoverBackground: "#f0f0f0d0",
    scrollbarThumb: "#cccccc",
    scrollbarTrack: "#f0f0f0",
    questionInputBackgroundColor: "#e8ebfa",
    questionInputTextColor: "#000",
    questionInputPlaceholderTextColor: "",
    answerTextColor: "#000",
    answerBackgroundColor: "#fff",
    bulbIconColor: "#000",
    clipboardIconColor: "#000",
    answerIconBackgroundColor: "#fff",
    userMessageBackgroundColor: "#e8ebfa",
    userMessageTextColor: "#000",
    chatSideBarButtonBorderColor: "#ccc",
    citationLabelColor: "#000",
    editIconButtonBackgroundColor: "#e8ebfa",
    editIconButtonFillColor: "#000",
    citationLinkTextColor: "#123bb6",
    citationLinkBackgroundColor: "#d1dbfa",
    citationSupBackgroundColor: "#d1dbfa",
    saveButtonBackgroundColor: "#4CAF50",
    saveButtonBorderColor: "#ccc",
    cancelButtonBackgroundColor: "#f0f0f0",
    cancelButtonBorderColor: "#ccc",
    cancelButtonTextColor: "#000",
    cancelButtonHoverColor: "#e0e0e0",
    confirmDialogBackgroundColor: "#fff",
    confirmDialogTitleColor: "#323130",
    confirmDialogMessageColor: "#605e5c",
    confirmDialogContinueButtonBackgroundColor: "#0078d4",
    confirmDialogContinueButtonTextColor: "#fff",
    confirmDialogCancelButtonBackgroundColor: "#fff",
    confirmDialogCancelButtonTextColor: "#000",
    errorDialogTitleColor: "#323130",
    errorDialogMessageColor: "#605e5c",
    fileUploadPanelBackgroundColor: "#fff",
    fileUploadPanelTextColor: "#000",
    fileUploadPanelTitleColor: "#323130",
    dropzoneTextColor: "#aaaaaa",
    dropzoneBorderColor: "#0078d4",
    dropzoneHoverColor: "#f1f9ff",
    fileUploadButtonActiveBackgroundColor: "#0078d4",
    fileUploadButtonDisabledBackgroundColor: "#f3f2f1",
    fileUploadButtonActiveTextColor: "#fff",
    fileUploadButtonDisabledTextColor: "#d2d0ce",
    fileUploadButtonActiveBorderColor: "#0078d4",
    fileUploadButtonDisabledBorderColor: "#f3f2f1",
    clearFilesButtonDisabledBackgroundColor: "#f3f2f1",
    clearFilesButtonActiveTextColor: "#fff",
    clearFilesButtonDisabledTextColor: "#a19f9d",
    clearFilesButtonActiveBackgroundColor: "#fff",
    closeButtonBackgroundColor: "#fff",
    closeButtonTextColor: "#323130",
    closeButtonHoverColor: "#ededed",
    crossButtonBackgroundColor: "#fff",
    crossButtonHoverColor: "#ededed",
    crossButtonTextColor: "#605e5c",
    checkboxTextColor: "#323130",
    checkboxBackgroundColor: "transparent",
    checkboxBorderColor: "#323130",
    sliderTextColor: "#323130",
    sliderActiveSectionColor: "#323130",
    sliderInactiveSectionColor: "#8a8886",
    spinbuttonTextColor: "#323130",
    spinbuttonInputBackgroundColor: "#fff",
    spinbuttonArrowBackgroundColor: "#f3f2f1",
    dropdownBorderColor: "#000",
    caretDownHoverColor: "#fff",
    caretDownColor: "#323130",
    disclaimerTextColor: "#002d4e",
    disclaimerBackgroundColor: "#8dc8e8",
    commandBackgroundColor: "#8dc8e8",
    promptGuidanceBackgroundColor: "#004171",
    pplDisclaimerBackgroundColor: "#1c6092",
    guidanceContainerTitle: "#333",
    guidanceContainerBackground: "#fcfcfc",
    guidanceContainerTextColor: "#333",
    guidanceContainerLinkColor: "#0066cc",
    examplePromptsBackgroundColor: "#f2f4ff",
    examplePromptsCardColor: "#004171",
    examplePromptsBorderColor: "#004171",
    examplePromptsTitleColor: "#000",
    examplePromptsTextColor: "#fff",
    examplePromptsDismissColor: "#666",
    historyItemMenuTextColor: "#000",
    historyItemMenuBackgroundColor: "#fff",
    historyItemMenuHoverColor: "#f0f0f0",
    historyItemMenuIconColor: "#0078d4",
    historyItemBorderColor: "#0094ff",
    successTextColor: "#00508d"
};

export const darkTheme = {
    backgroundColor: "#141516",
    cardDescriptionTextColor: "#e4e4e4",
    headerBackground: "#010101",
    cardStrip1Color: "#000",
    cardColor: "#232323",
    disabledCardColor: "#232323",
    headerTextColor: "#fff",
    cardTitleColor: "#cedee9",
    landingPageTitleColor: "#e2f3ff",
    cardFlipTextColor: "#e3e3e3",
    backgroundImage: "",
    iconFillColor: "#e2f3ff",
    cardDescriptionHoverTextColor: "#fff",
    cardTitleHoverColor: "#e2f3ff",
    cardFlipHoverTextColor: "#e2f3ff",
    cardLinkTextColor: "#8bc3ff",
    chatBackgroundImage: "",
    chatBackgroundColor: "#141516",
    chatTitleColor: "#e2f3ff",
    chatIconFillColor: "#e2f3ff",
    chatCommandTextColor: "#e2f3ff",
    chatCommandContainerBackgroundColor: "",
    chatSidebarBackground: "#010101",
    chatSidebarItem: "#fff",
    chatSidebarActiveItem: "#141516",
    chatSidebarItemHover: "#141516",
    chatSidebarItemBorder: "#2b2b2b",
    chatSidebarButtonBackground: "#141516",
    chatSidebarButtonTextColor: "#fff",
    chatSidebarButtonHoverBackground: "#1d1e1f",
    scrollbarThumb: "#555555",
    scrollbarTrack: "#222222",
    questionInputBackgroundColor: "#20242d",
    questionInputTextColor: "#fff",
    questionInputPlaceholderTextColor: "#e4e4e4",
    answerTextColor: "#fff",
    answerBackgroundColor: "#2a2a2a",
    bulbIconColor: "#e4e4e4",
    clipboardIconColor: "#e4e4e4",
    answerIconBackgroundColor: "#2a2a2a",
    userMessageBackgroundColor: "#20242d",
    userMessageTextColor: "#fff",
    chatSideBarButtonBorderColor: "#595959",
    citationLabelColor: "#fff",
    editIconButtonBackgroundColor: "#20242d",
    editIconButtonFillColor: "#fff",
    citationLinkBackgroundColor: "#2a2f41",
    citationLinkTextColor: "#8bc3ff",
    citationSupBackgroundColor: "#2d385f",
    saveButtonBackgroundColor: "#429245",
    saveButtonBorderColor: "#20242d",
    cancelButtonBackgroundColor: "#20242d",
    cancelButtonBorderColor: "#5a606d",
    cancelButtonTextColor: "#fff",
    cancelButtonHoverColor: "#2d323f",
    confirmDialogBackgroundColor: "#000",
    confirmDialogTitleColor: "#ededed",
    confirmDialogMessageColor: "#fff",
    confirmDialogContinueButtonBackgroundColor: "#0078d4",
    confirmDialogContinueButtonTextColor: "",
    confirmDialogCancelButtonBackgroundColor: "",
    confirmDialogCancelButtonTextColor: "",
    errorDialogTitleColor: "#ededed",
    errorDialogMessageColor: "#fff",
    fileUploadPanelBackgroundColor: "#141516",
    fileUploadPanelTextColor: "#fff",
    fileUploadPanelTitleColor: "#ededed",
    dropzoneTextColor: "#797979",
    dropzoneBorderColor: "#004d88",
    dropzoneHoverColor: "#2a2a2a",
    fileUploadButtonActiveBackgroundColor: "#2a2a2a",
    fileUploadButtonDisabledBackgroundColor: "#141516",
    fileUploadButtonActiveTextColor: "#fff",
    fileUploadButtonDisabledTextColor: "#a19f9d",
    fileUploadButtonActiveBorderColor: "#2a2a2a",
    fileUploadButtonDisabledBorderColor: "#626262",
    clearFilesButtonDisabledBackgroundColor: "#141516",
    clearFilesButtonActiveTextColor: "#fff",
    clearFilesButtonDisabledTextColor: "#a19f9d",
    clearFilesButtonActiveBackgroundColor: "#141516",
    closeButtonBackgroundColor: "#2a2a2a",
    closeButtonTextColor: "#fff",
    closeButtonHoverColor: "#373737",
    crossButtonBackgroundColor: "#141516",
    crossButtonHoverColor: "#373737",
    crossButtonTextColor: "#ededed",
    checkboxTextColor: "#fff",
    checkboxBackgroundColor: "#fff",
    checkboxBorderColor: "#848484",
    sliderTextColor: "#fff",
    sliderActiveSectionColor: "#cccccc",
    sliderInactiveSectionColor: "#454545",
    spinbuttonTextColor: "#fff",
    spinbuttonInputBackgroundColor: "#2a2a2a",
    spinbuttonArrowBackgroundColor: "#313131",
    dropdownBorderColor: "#605e5c",
    caretDownHoverColor: "#9e9e9d",
    caretDownColor: "#919191",
    disclaimerTextColor: "#ededed",
    disclaimerBackgroundColor: "#141516",
    commandBackgroundColor: "#0d0e0e",
    promptGuidanceBackgroundColor: "#2a2a2a",
    pplDisclaimerBackgroundColor: "#2c3744",
    guidanceContainerTitle: "#ededed",
    guidanceContainerBackground: "#2a2a2a",
    guidanceContainerTextColor: "fff",
    guidanceContainerLinkColor: "#7bc9ff",
    examplePromptsBackgroundColor: "#272a31",
    examplePromptsCardColor: "#2a2a2a",
    examplePromptsBorderColor: "#080808",
    examplePromptsTitleColor: "#fff",
    examplePromptsTextColor: "#fff",
    examplePromptsDismissColor: "#fff",
    historyItemMenuTextColor: "#fff",
    historyItemMenuBackgroundColor: "#2a2a2a",
    historyItemMenuHoverColor: "#373737",
    historyItemMenuIconColor: "#b4d1e6",
    historyItemBorderColor: "#8bc3ff",
    successTextColor: "#0287ed"
};