import { Outlet, NavLink, Link } from "react-router-dom";
import styles from "./Layout.module.css";
import { IconButton } from "@fluentui/react";
import fonterralogo from "../../assets/fonterralogo.png";

import { ReleaseNotesUrl } from "../../config/frontendconfig";

import { useTheme } from '../../state/themecontext';
import { ThemeToggleButton } from "../../components/ThemeToggleButton";

const appVersion = import.meta.env.VITE_APP_VERSION || "Preview 0.5";

// track Fonterra logo clicks
const onLogoClick = (componentClicked: string) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ component: null });
    window.dataLayer.push({
      event: 'component_interaction',
      component: {
        component_name: 'header navigation links',
        component_title: document.title,
        component_type: 'logo',
        interaction_type: 'click',
        interaction_value: componentClicked,
        interaction_url: 'https://chatgpt.apps.fonterra.com/'
      }
    });
}

const Layout = () => {    
    const { toggleTheme, theme } = useTheme(); 
    return (
        <div className={styles.layout}>
            <header className={styles.header} role={"banner"}>
                <div className={styles.headerContainer} >
                    <Link to="/" className={styles.headerTitleContainer} onClick={() => {onLogoClick('Dairy for life/Fonterra logo')}} >
                        <img src={fonterralogo} loading="lazy" alt="fonterra logo" style={{ width: 80, height: 64 }} />
                    </Link> 
                    <Link to="/" className={styles.headerTitleContainer} onClick={() => {onLogoClick('Co-op GPT header')}} >
                        <h3 className={styles.headerTitle}>Co-op GPT</h3>
                    </Link>
                    <div className={styles.rightGroup}>
                        <ThemeToggleButton theme={theme} toggleTheme={toggleTheme} />
                        <a href={ReleaseNotesUrl} target="_blank" rel="noopener noreferrer" className={styles.headerVersionContainer}>
                            <h5 className={styles.headerVersion}>{appVersion}</h5>
                        </a>
                    </div>
                </div>
            </header>
            <Outlet />
        </div>
    );
};

export default Layout;
