import React from 'react';
import { ArrowRepeatAll24Regular } from "@fluentui/react-icons";
import styles from './FlipCard.module.css';
import ReactCardFlip from 'react-card-flip';
import { useNavigate } from 'react-router-dom';
import parse from 'html-react-parser';

interface NavigateProps {
    pathname: string;
    state?: {
        cardIdentifier?: string;
    };
}

interface FlipCardProps {
    cardStyle: string;
    isFlipped: boolean;
    handleFlip: (e: React.MouseEvent) => void;
    title: string;
    description: string;
    backDescription: string;
    navigateTo: string | NavigateProps;
    disabled?: boolean;
}

export const FlipCard: React.FC<FlipCardProps> = ({
    cardStyle,
    isFlipped,
    handleFlip,
    title,
    description,
    navigateTo,
    disabled,
    backDescription
}) => {
    const navigate = useNavigate();

    const navigateToPage = () => {
        if (typeof navigateTo === "object" && navigateTo.pathname) {
            pushDataLayerEvent('click', title, navigateTo.pathname);
            navigate(navigateTo.pathname, { state: navigateTo.state });
        } else {
            pushDataLayerEvent('click', title, navigateTo as string);
            navigate(navigateTo as string);
        }
    };

    const handleFlipWithTracking = (e: React.MouseEvent) => {
        pushDataLayerEvent('flip', title);
        handleFlip(e);
    };

    const pushDataLayerEvent = (interactionType: 'click' | 'flip', interactionValue: string, interactionUrl?: string) => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ component: null });
        window.dataLayer.push({
            event: 'component_interaction',
            component: {
                component_name: 'tile',
                component_title: title,
                component_type: 'card',
                interaction_type: interactionType,
                interaction_value: interactionValue,
                interaction_url: interactionUrl
            }
        });
    };

    const handleUserGuideClick = (e: React.MouseEvent, url: string) => {
        e.stopPropagation();
        pushDataLayerEvent('click', 'user guide', url);
        window.open(url, '_blank');
    };

    const renderDescription = (htmlContent: string) => {
        return parse(htmlContent, {
            replace: (domNode: any) => {
                if (domNode.name === 'a' && domNode.attribs && domNode.attribs.href) {
                    return (
                        <a
                            href={domNode.attribs.href}
                            onClick={(e) => handleUserGuideClick(e, domNode.attribs.href)}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {domNode.children[0].data}
                        </a>
                    );
                }
            }
        });
    };

    return (
        <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
            {/* Front Side */}
            <div className={`${styles.card} ${cardStyle} ${disabled ? styles.disabledCard : ''}`}>
                <div className={`${styles.textContainer} ${disabled ? styles.disabledText : ''}`} onClick={navigateToPage}>
                    <div className={styles.title}>{title}</div>
                    <div className={styles.description}>
                        {renderDescription(description)}
                    </div>
                </div>
                <div className={styles.flipContainer} onClick={handleFlipWithTracking}>
                    <span className={styles.flipText}>Click to flip</span>
                    <ArrowRepeatAll24Regular className={styles.flipButton} />
                </div>
            </div>

            {/* Back Side */}
            <div className={`${styles.card} ${cardStyle} ${disabled ? styles.disabledCard : ''}`}>
                <div className={`${styles.textContainer} ${disabled ? styles.disabledText : ''}`} onClick={navigateToPage}>
                    <div className={styles.title}>{title}</div>
                    <div className={styles.description}>
                        {renderDescription(backDescription)}
                    </div>
                </div>
                <div className={styles.flipContainer} onClick={handleFlipWithTracking}>
                    <span className={styles.flipText}>Click to flip</span>
                    <ArrowRepeatAll24Regular className={styles.flipButton} />
                </div>
            </div>
        </ReactCardFlip>
    );
    
};

export default FlipCard;